import {Module} from 'vuex'

export interface IAppModule {
    showNavigation: boolean
}

export const app: Module<IAppModule, any> = {
    namespaced: true,
    state: {
        showNavigation: false
    } as IAppModule,
    getters: {
        showNavigation: state => state.showNavigation ?? false
    },
    mutations: {
        toggleShowNavigation(state) {
            state.showNavigation = !state.showNavigation
        },

        setShowNavigation(state, value: boolean) {
            state.showNavigation = value
        }
    },
}