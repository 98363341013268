
	import {Component, Mixins, Vue} from 'vue-property-decorator'
    import {store} from '@/store'
    import NavigationList from './NavigationList.vue'
    import ToggleNightMode from '@/components/Header/ToggleNightMode.vue'
    import {IRouterMeta} from '@/typings/IRouterMeta'
	import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'

    @Component({
        components: {
            NavigationList,
            ToggleNightMode
        }
    })
    export default class SiteNavigation extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        addChatButtonLoading = false

        get showNavigation() {
            return store.getters['app/showNavigation']
        }

        get show() {
            return this.showNavigation || this.isDesktop
        }

        set show(value: boolean) {
            store.commit('app/setShowNavigation', value)
        }
    }
