
    import {Component, Mixins, Ref, Vue, Watch} from 'vue-property-decorator'
    import {IActor} from '@/typings/IActor'
    import Requester from '@/requester/Requester'
    import {IListResponse, IListResponseMeta} from '@/requester/typings'
    import MarketplaceChartItem from '@/pages/Marketplace/MarketplaceChartItem.vue'
    import {Debounce} from '@/decorator'
    import {redirectToChatbotPage} from '@/utils'
    import searchIcon from '@/assets/icons/search_icon.svg'
    import InlineSvgComponent from 'vue-inline-svg'
    import {store} from '@/store'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'

    @Component({
        components: {
            MarketplaceChartItem,
            InlineSvgComponent,
        },
    })
    export default class HeaderActorSearch extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Ref('r-search') searchRef!: HTMLDivElement
        currentActors: Array<IActor> = []

        loading = false

        search: string = ''

        lastMeta: IListResponseMeta | null = null
        currentPage: number = 1
        perPage: number = 20

        loadId = 0

        isNextLoading = false

        get backgroundColor() {
            return this.nightMode ? '#1A1D1F' : '#EFEFEF'
        }

        get nightMode() {
            return store.getters['local_storage/darkMode']
        }

        get iconColor() {
            return '#6F767E'
        }

        get searchIcon() {
            return searchIcon
        }

        @Debounce(200)
        @Watch('search')
        async onSearchChanged() {
            this.loadId++
            await this.loadNew(this.loadId)
        }

        async redirectToPage(actor: IActor) {
            this.searchRef.blur()
            await redirectToChatbotPage(actor)
        }

        async endIntersect(entries: Array<IActor>, observer: IntersectionObserver, isIntersecting: boolean) {
            if (!isIntersecting) {
                return
            }

            await this.loadNext()
        }

        async load(page: number, search: string) {
            const response = await Requester.get<IListResponse<IActor>>(
                'chat/actors',
                {
                    page,
                    per_page: this.perPage,
                    search: search?.trim() ?? undefined,
                },
                true
            )

            if (response.type === 'error') {
                throw new Error('Error occured')
            }

            return response
        }

        async loadNext() {
            if (this.loading) {
                return
            }

            if (!this.lastMeta) {
                return
            }

            if (this.lastMeta?.currentPage >= this.lastMeta.pageCount) {
                return
            }

            this.isNextLoading = true
            this.currentPage++
            const response = await this.load(this.currentPage, this.search)

            this.lastMeta = response.response.data._meta
            this.currentActors = [...this.currentActors, ...response.response.data.items]
            this.isNextLoading = false
        }

        async loadNew(loadId: number) {
            this.loading = true

            this.currentPage = 1
            const response = await this.load(this.currentPage, this.search)

            if (this.loadId !== loadId) {
                return
            }

            this.lastMeta = response.response.data._meta
            this.currentActors = response.response.data.items
            this.loading = false
        }

        async mounted() {

        }
    }
