import {initializeApp} from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export function initFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyDLcgvm3NVBrqY_xwoDKrulpWEpEtkqmPA",
        authDomain: "chat-ai-377004.firebaseapp.com",
        projectId: "chat-ai-377004",
        storageBucket: "chat-ai-377004.appspot.com",
        messagingSenderId: "311765947557",
        appId: "1:311765947557:web:9e80bbf684eb7f4803694c",
        measurementId: "G-X2L3Q34HPR"
    };

    initializeApp(firebaseConfig);

    const messaging = getMessaging();

    getToken(messaging, { vapidKey: 'BGubPXfzSxNiiD6VNTXI_0BxVymLY_wANTkp5P-lQK_EdrD4jNNWuSwpVHSPBX66PL65R3pRbPYLAGCw4SVoAk4' }).then((currentToken) => {
        if (currentToken) {
            console.log(`token "${currentToken}"`)
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });

    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
    });
}
