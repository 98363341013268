import  {Module} from 'vuex'
import IShortcutModel from '@/typings/IShortcutModel'
import IChat from '@/typings/IChat'
import IChatMessage from '@/typings/IChatMessage'
import {getLocalStorage, saveLocalStorage} from '@/store/utils'

export interface ILocalStorageModule {
    darkMode: boolean
    shortcuts: Array<IShortcutModel> // TODO remove me
    chats: Array<IChat>
    currentChatId: string | null
    sendCodeLastTime: string | null
}

export const local_storage: Module<ILocalStorageModule, any> = {
    namespaced: true,
    state: {
        darkMode: getStorageState().darkMode ?? true,
        shortcuts: getStorageState().shortcuts ?? [],
        sendCodeLastTime: getStorageState().sendCodeLastTime ?? null
    } as ILocalStorageModule,
    getters: {
        darkMode: state => state.darkMode ?? getStorageState().darkMode ?? true,
        shortcuts: state => state.shortcuts ?? getStorageState().shortcuts ?? [],
        sendCodeLastTime: state => state.sendCodeLastTime ?? getStorageState().sendCodeLastTime ?? null
    },
    mutations: {
        sendCodeLastTime(state, value: Date) {
            state.sendCodeLastTime = new Date(value).toISOString()
            saveStorage(state)
        },

        darkMode(state, value: boolean) {
            state.darkMode = value
            saveStorage(state)
        },

        addShortcut(state, shortcut: IShortcutModel) {
            state.shortcuts.unshift(shortcut)
            saveStorage(state)
        },

        replaceShortcuts(state, shortcut: Array<IShortcutModel>) {
            state.shortcuts = shortcut
            saveStorage(state)
        },

        editShortcut(state, v: {index: number, shortcut: IShortcutModel}) {
            state.shortcuts[v.index] = v.shortcut
            saveStorage(state)
        },

        removeShortcut(state, index: number) {
            const shortcuts = state.shortcuts
            shortcuts.splice(index, 1)
            state.shortcuts = shortcuts
            saveStorage(state)
        },
    },
    actions: {

    }
}

function getStorageState() {
    return getLocalStorage<ILocalStorageModule>('state')
}

function saveStorage(state: ILocalStorageModule) {
    saveLocalStorage('state', state)
}