import {ILocalStorageModule} from '@/store/modules/local_storage'

const CURRENT_VERSION = 1

export function initStorages() {
    localStorage.removeItem('chat_ai_web_state')
    localStorage.removeItem('chat_ai_web_auth')
    localStorage.removeItem('chat_ai_web_chat')

    const allData = JSON.parse(localStorage.getItem('chat_ai_web_storage') ?? '{}')
    if (!allData.__version || allData.__version !== CURRENT_VERSION) {
        localStorage.setItem('chat_ai_web_storage', '{}')
    }
}

export function getLocalStorage<T>(key: string): T {
    return JSON.parse(localStorage.getItem('chat_ai_web_storage') ?? '{}')?.[key] ?? {}
}

export function saveLocalStorage(key: string, value: any) {
    const allData = JSON.parse(localStorage.getItem('chat_ai_web_storage') ?? '{}')
    allData[key] = value
    allData.__version = CURRENT_VERSION
    localStorage.setItem('chat_ai_web_storage', JSON.stringify(allData))
}