import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import IUser from '@/typings/IUser'
import {store} from '@/store'
import {Buffer} from 'buffer'
import IAuthUser from '@/typings/IAuthUser'
import {IRequestLog} from '@/store/modules/dev_logs'

export function getV2AccessToken() {
    const {createCipheriv} = require('browser-crypto')
    const cipher = createCipheriv('aes-256-gcm', process.env.VUE_APP_AES_SECRET, Buffer.from(process.env.VUE_APP_AES_SECRET))
    return cipher.update(`app_${Math.floor(new Date().getTime() / 1000)}`, 'utf-8', 'hex');
}

function getV2Headers() {
    if (!process.env.VUE_APP_AES_SECRET) {
        return {}
    }

    return {
        Authorization: `Bearer ${getV2AccessToken()}`,
    }
}

function getV3AccessToken() {
    return store.getters['auth/token']
}

function getAPIType(allowNoAuth: boolean) {
    const token = getV3AccessToken()
    if (!allowNoAuth || token) {
        return 'api'
    }

    return 'web-api'
}

function getAPIVersion(allowNoAuth: boolean) {
    const apiType = getAPIType(allowNoAuth)
    return apiType === 'api' ? 'v3' : 'v2'
}

function getV3Headers() {
    const token = getV3AccessToken()

    if (!token) {
        return {}
    }

    return {
        Authorization: `Bearer ${token}`,
    }
}

export interface IDefaultError {
    message: string
}

export type IRequesterStreamResponse = IRequesterStreamSuccessResponse | IRequesterStreamErrorResponse

export interface IRequesterStreamErrorResponse {
    type: 'error'
    response: any
}

export interface IRequesterStreamSuccessResponse {
    type: 'success'
    response: ReadableStream
}

export type IRequesterResponse<T> = IRequesterSuccessResponse<T> | IRequesterErrorResponse<IDefaultError>

export interface IRequesterErrorResponse<T = any> {
    type: 'error'
    response: AxiosError<T>
}

export interface IRequesterSuccessResponse<T = any> {
    type: 'success'
    response: AxiosResponse<T>
}

export default class Requester {
    static async auth(email: string, password: string): Promise<IRequesterResponse<IAuthUser>> {
        return await defaultRequestWrapper<IAuthUser>({
            method: 'POST',
            url: `${process.env.VUE_APP_NODE_SERVER}/api/v3/auth/login`,
            data: {
                email,
                password,
            },
        })
    }

    static async registration(data: Partial<IUser>): Promise<IRequesterResponse<IAuthUser>> {
        return await defaultRequestWrapper<IAuthUser>({
            method: 'POST',
            url: `${process.env.VUE_APP_NODE_SERVER}/api/v3/auth/register`,
            data,
        })
    }

    static async postV2<T extends any>(endpoint: string, data: any, params: any = {}): Promise<IRequesterResponse<T>> {
        return await defaultRequestWrapper({
            method: 'POST',
            url: `${process.env.VUE_APP_NODE_SERVER}/${process.env.VUE_APP_V2_API_PATH}/v2/${endpoint}`,
            headers: getV2Headers(),
            data,
            params,
        })
    }

    static async streamPost(endpoint: string, data: any, params: any = {}): Promise<IRequesterStreamResponse> {
        try {
            const response = await fetch(`${process.env.VUE_APP_NODE_SERVER}/api/v3/${endpoint}?${new URLSearchParams(params)}`,{
                //@ts-ignore
                headers: {
                    ...getV3Headers(),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            })

            return {
                type: 'success',
                response: response.body as ReadableStream,
            }

        } catch (e) {
            return {
                type: 'error',
                response: e
            }
        }
    }

    static async get<T>(endpoint: string, params: any = {}, allowNoAuth = false): Promise<IRequesterResponse<T>> {
        return await defaultRequestWrapper<T>({
            method: 'GET',
            url: `${process.env.VUE_APP_NODE_SERVER}/${getAPIType(allowNoAuth)}/${getAPIVersion(allowNoAuth)}/${endpoint}`,
            headers: getV3Headers(),
            params,
        })
    }

    static async patch<T extends any>(endpoint: string, data: any, params: any = {}): Promise<IRequesterResponse<T>> {
        return await defaultRequestWrapper<T>({
            method: 'PATCH',
            url: `${process.env.VUE_APP_NODE_SERVER}/api/v3/${endpoint}`,
            data,
            headers: getV3Headers(),
            params,
        })
    }

    static async post<T extends any>(endpoint: string, data: any, params: any = {}): Promise<IRequesterResponse<T>> {
        return await defaultRequestWrapper<T>({
            method: 'POST',
            url: `${process.env.VUE_APP_NODE_SERVER}/api/v3/${endpoint}`,
            data,
            headers: getV3Headers(),
            params
        })
    }

    static async delete(endpoint: string, params: any = {}) {
        return await defaultRequestWrapper({
            method: 'DELETE',
            url: `${process.env.VUE_APP_NODE_SERVER}/api/v3/${endpoint}`,
            headers: getV3Headers(),
            params
        })
    }
}

export async function defaultRequestWrapper<T>(config: AxiosRequestConfig): Promise<IRequesterResponse<T>> {

    try {
        const response = await axios.request(config)
        store.commit('dev_logs/addLog', {
            type: 'success',
            url: config.url,
            //@ts-ignore
            useToken: !!config.headers?.['Authorization'],
            params: config.params,
            data: config.data,
            response: response.data
        } as IRequestLog)

        return {
            type: 'success',
            response: response
        } as IRequesterSuccessResponse<T>
    } catch (e) {
        store.commit('dev_logs/addLog', {
            type: 'error',
            url: config.url,
            //@ts-ignore
            useToken: !!config.headers?.['Authorization'],
            params: config.params,
            data: config.data,
            response: e
        } as IRequestLog)

        return {
            type: 'error',
            response: e as AxiosError<IDefaultError>
        } as IRequesterErrorResponse
    }
}