
    import {Component, Vue} from 'vue-property-decorator'
    import diamondIcon from '@/assets/icons/diamond_icon.svg'
    import InlineSvgComponent from 'vue-inline-svg'
    import {store} from '@/store'

    @Component({
        components: {
            InlineSvgComponent
        }
    })
    export default class BuyBlock extends Vue {
        get fillColor() {
            return store.getters['local_storage/darkMode'] ? '#FFFFFF' : '#000000'
        }

        get diamondIcon() {
            return diamondIcon
        }

        async goToBuySubscriptionPage() {
            await this.$router.push({name: 'buy'})
        }
    }
