
    import {Component, Mixins} from 'vue-property-decorator'
    import InlineSvgComponent from 'vue-inline-svg'
    import IconMixins from '@/mixins/IconMixins'
    import {store} from '@/store'

    @Component({
        components: {
            InlineSvgComponent
        }
    })
    export default class ToggleNightMode extends Mixins<IconMixins>(IconMixins) {
        get nightMode(): boolean {
            return store.getters['local_storage/darkMode']
        }

        set nightMode(value: boolean) {
            store.commit('local_storage/darkMode', value)
        }
    }
