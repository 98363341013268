
    import {Component, Mixins} from 'vue-property-decorator'
    import BuyBlock from '@/pages/Chat/BuyBlock.vue'
    import ChatList from '@/pages/Chat/ChatList.vue'
    import {store} from '@/store'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'
    import ToggleNightMode from '@/components/Header/ToggleNightMode.vue'
    import {IRouterMeta} from '@/typings/IRouterMeta'

    @Component({
        components: {
            BuyBlock,
            ChatList,
            ToggleNightMode,
        },
    })
    export default class CreateChatBlock extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        addChatButtonLoading = false

        get showNavigation() {
            return store.getters['app/showNavigation']
        }

        get show() {
            return this.showNavigation || (this.isDesktop && !this.meta.chatBlockForceHide)
        }

        set show(value: boolean) {
            store.commit('app/setShowNavigation', value)
        }

        get drawerParams() {
            return this.isMobile || this.meta.chatBlockForceHide ? {absolute: true} : {}
        }

        get meta(): IRouterMeta {
            return this.$route.meta as IRouterMeta
        }

        get hasSubscription() {
            return true
        }

        async createChat() {
            this.addChatButtonLoading = true
            await store.dispatch('chat/createChat')
            this.addChatButtonLoading = false
        }
    }
