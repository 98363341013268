
    import {Component, Vue} from 'vue-property-decorator'
    import TabButton from '@/components/Header/TabButton.vue'
    import {Buttons} from '@/components/Header/buttons'

    @Component({
        components: { TabButton }
    })
    export default class TabGroup extends Vue {
        get currentTab() {
            return this.$route.name
        }

        get buttons() {
            return Buttons
        }
    }
