import {Module} from 'vuex'
import {cloneDeep} from 'lodash'
import Requester from '@/requester/Requester'
import IAuthUser from '@/typings/IAuthUser'
import {Permission} from '@/typings/IRouterMeta'
import {getLocalStorage, saveLocalStorage} from '@/store/utils'
import router from '@/router'

export interface IAuthModule {
    me: IAuthUser | null
    token: string | null
    permission: Permission
}

export const auth: Module<IAuthModule, any> = {
    namespaced: true,
    state: {
        me: null,
        token: null,
        permission: 'no-auth'
    } as IAuthModule,
    getters: {
        state: state => state,
        isFullAuth: state => state.token && state.me,
        me: state => state.me,
        permission: state => state.permission,
        token: state => state.token ?? getStorageState()?.token ?? null
    },
    mutations: {
        setUser(state, user: IAuthUser) {
            state.me = user
            state.token = user.access_token ?? getStorageState()?.token ?? null
            state.permission = user.is_confirmed ? 'full-auth' : 'not-confirmed'
            saveStorage(state)
        },

        clear(state) {
            state.me = null
            state.token = null
            state.permission = 'no-auth'
            saveStorage(state)
        }
    },
    actions: {
        async logout({commit, dispatch}) {
            dispatch('chat/$reset', null, {root: true})
            commit('clear')
            await router.push({name: 'login'})
        },

        async requestSelf({state, commit, getters}) {
            if (getters.me) {
                return
            }

            if (!getters.token) {
                return
            }

            const res = await Requester.get<IAuthUser>('auth/me')

            if (res.type === 'error') {
                commit('logout')
            } else {
                commit('setUser', res.response.data)
            }
        },
    }
}

function getStorageState() {
    return getLocalStorage<IAuthModule>('auth')
}

function saveStorage(state: IAuthModule) {
    const clonedState: Partial<IAuthModule> = cloneDeep(state)
    delete clonedState.me
    delete clonedState.permission
    saveLocalStorage('auth', clonedState)
}