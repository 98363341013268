import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"right":"","min-width":"200","nudge-top":"-10","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c(VBtn,_vm._g(_vm._b({staticClass:"account-button",attrs:{"outlined":"","width":"32","height":"32","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-account ")])],1)]}}])},[_c(VList,[(_vm.user)?_c(VListItem,[_vm._v(" "+_vm._s(_vm.userName)+" ")]):_vm._e(),(_vm.user)?_c(VDivider):_vm._e(),_c(VListItemGroup,[_c(VListItem,{on:{"click":_vm.toChatbots}},[_c('span',{staticClass:"ml-2 link-title-text"},[_vm._v("Chatbots")])]),_c(VListItem,{on:{"click":_vm.toAbout}},[_c('span',{staticClass:"ml-2 link-title-text"},[_vm._v("About")])]),(_vm.user)?_c(VListItem,{on:{"click":_vm.logout}},[_c(VIcon,[_vm._v(" mdi-logout ")]),_c('span',{staticClass:"ml-2 link-title-text"},[_vm._v("Logout")])],1):_c(VListItem,{on:{"click":_vm.toLogin}},[_c(VIcon,[_vm._v(" mdi-login ")]),_c('span',{staticClass:"ml-2 link-title-text"},[_vm._v("Login")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }