
    import {Component, Mixins, Ref, Watch} from 'vue-property-decorator'
    import {store} from '@/store'
    import IChat from '@/typings/IChat'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'
    import {IListResponseMeta} from '@/requester/typings'
    import AlertDialog from '@/components/Other/AlertDialog.vue'
    import {orderBy} from 'lodash'

    @Component({
        components: {
            AlertDialog
        }
    })
    export default class NavigationList extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        get navigationArray() {
            return [
                {
                    to: 'chatbots',
                    name: 'Chatbots',
                    icon: ''
                },
                {
                    to: 'about',
                    name: 'About',
                    icon: ''
                },
                {
                    to: 'login',
                    name: 'Login',
                    available: () => !this.user
                },
                {
                    to: 'registration',
                    name: 'Registration',
                    available: () => !this.user
                }
            ]
        }

        get user() {
            return store.getters['auth/me']
        }

        getClass(name: string) {
            console.log(this.$route.name, name)
            return this.$route.name === name
                ? 'active'
                : ''
        }

        onClick(name: string) {
            this.$router.push({name})
        }
    }
