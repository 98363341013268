
    import {Component, Mixins, Model} from 'vue-property-decorator'
    import HeaderLogo from '@/components/Header/HeaderLogo.vue'
    import ToggleNightMode from '@/components/Header/ToggleNightMode.vue'
    import TabGroup from '@/components/Header/TabGroup.vue'
    import HeaderInfoButtonGroup from '@/components/Header/HeaderInfoButtonGroup.vue'
    import {IRouterMeta} from '@/typings/IRouterMeta'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'
    import burgerIcon from '@/assets/icons/burger_icon.svg'
    import {store} from '@/store'
    import {Buttons} from '@/components/Header/buttons'
    import HeaderActorSearch from '@/components/Header/HeaderActorSearch.vue'
    import IAuthUser from '@/typings/IAuthUser'

    @Component({
        components: {
            HeaderActorSearch,
            HeaderLogo,
            ToggleNightMode,
            TabGroup,
            HeaderInfoButtonGroup
        }
    })
    export default class Header extends Mixins<AdaptiveMixin>(AdaptiveMixin)  {
        @Model('update') showMenu!: boolean

        get user(): IAuthUser {
            return store.getters['auth/me']
        }

        get isChatBlock() {
            return ((this.isMobile || this.meta.chatBlockForceHide) && this.hasChatBlock && this.user)
        }

        get isSiteNavigation() {
            return !this.isChatBlock && this.meta.hasNavigationBlock
        }

        get hasNavigationBlock() {
            return this.meta.hasNavigationBlock
        }

        get hasChatBlock() {
            return this.meta.hasChatBlock
        }

        get hasActorSearch() {
            return this.meta.hasActorSearch
        }

        get tabGroupAvailable() {
            return Buttons.length > 1
        }

        get show() {
            return store.getters['app/showNavigation']
        }

        get burgerIcon() {
            return burgerIcon
        }

        get metaStyle() {
            return this.meta.transparentHeader ? {backgroundColor: '#00000000 !important'} : null
        }

        get hasRoutes() {
            return this.meta.hasMainRoutes
        }

        get meta(): IRouterMeta {
            return this.$route.meta as IRouterMeta
        }

        toggleMenu() {
            store.commit('app/toggleShowNavigation')
        }
    }
