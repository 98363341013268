
    import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'
    import {IHeaderToggleButton} from '@/components/Header/buttons'
    import InlineSvgComponent from 'vue-inline-svg'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'

    @Component({
        components: {
            InlineSvgComponent,
        },
    })
    export default class TabButton extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) value!: IHeaderToggleButton

        get color() {
            return this.$route.name === this.value.path
                ? this.$vuetify.theme.currentTheme.primary
                : '#6F767E'
        }

        onClick() {
            this.$router.push({name: this.value.path})
        }
    }
