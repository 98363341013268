
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class AlertDialog extends Vue {
        @Prop({required: true}) chatId!: number
        dialog = false

        onCancel() {
            this.dialog = false
        }

        onDelete() {
            this.dialog = false
            this.$emit('delete', this.chatId)
        }
    }
