
    import {Component, Mixins} from 'vue-property-decorator'
    import ToggleNightMode from '@/components/Header/ToggleNightMode.vue'
    import HeaderOnline from '@/components/Header/HeaderOnline.vue'
    import googleLogo from '@/assets/landing/google_logo.svg'
    import appleLogo from '@/assets/landing/apple_logo.svg'
    import AccountFastMenu from '@/components/Header/AccountFastMenu.vue'
    import IAuthUser from '@/typings/IAuthUser'
    import {store} from '@/store'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'

    @Component({
        components: {
            HeaderOnline,
            ToggleNightMode,
            AccountFastMenu
        }
    })
    export default class HeaderInfoButtonGroup extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        get user(): IAuthUser {
            return store.getters['auth/me']
        }

        get googleIcon() {
            return googleLogo
        }

        get appleIcon() {
            return appleLogo
        }

        toChatbots() {
            store.commit('app/setShowNavigation', false)
            this.$router.push({name: 'chatbots'})
        }

        toAbout() {
            store.commit('app/setShowNavigation', false)
            this.$router.push({name: 'about'})
        }

        toLogin() {
            store.commit('app/setShowNavigation', false)
            this.$router.push({name: 'login'})
        }

        redirectToApple() {
            window.open('https://apple.co/428qe7X', '_blank')
        }

        redirectToAndroid() {
            window.open('https://bit.ly/3l7fC8I', '_blank')
        }
    }
