import Vue from 'vue'
import Vuex, {Store} from 'vuex'
import {local_storage} from '@/store/modules/local_storage'
import {chat} from '@/store/modules/chat'
import {app} from '@/store/modules/app'
import {auth} from '@/store/modules/auth'
import {dev_logs} from '@/store/modules/dev_logs'

Vue.use(Vuex)

export const store = new Store<{}>({
  modules: {
    chat,
    local_storage,
    app,
    auth,
    dev_logs,
  },
})