import {
    CHAT_PART_GROUP,
    GROUPS,
    IMessagePartGroup,
    IMessagePartGroupRule,
    parseSplitBlocks
} from "@/pages/Chat/Parts/utils";

export const BULLET = '•'

export function splitMessages(message: string) {
    const parts = message.split(/\n\n/)
        .filter(v => v !== '')

    return parts.map(p => p.split('\n'))
}

export function formatMessageFromHTML(html: string) {

}

export function formatCopiedMessage(message: string) {
    const splitted = splitMessages(message)
    const blocks = splitted.map(v => parseSplitBlocks(v))

    const rules: Partial<Record<CHAT_PART_GROUP, IMessagePartGroupRule>> = {}

    for (const rule of GROUPS) {
        rules[rule.name] = rule
    }
    const parts = []

    for (const block of blocks) {
        for (const group of block) {
            const res = group.texts.map(
                (text, i) => !!group.type
                    ? `${rules[group.type!]?.universalFormatter(i)} ${rules[group.type!]?.formatter(text)}`
                    : text
            ).join('\n')

            parts.push(res)
        }
    }

    return parts.join('\n\n')
}

export function messagePartGroupToString(messagePartGroup: IMessagePartGroup) {
    switch (messagePartGroup.type) {
        case 'dot-list':
            return
        case 'text-list':
        case 'number-list':
        default:
            return messagePartGroup.texts.join('\n')
    }
}