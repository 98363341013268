
    import {Component, Mixins, Ref, Watch} from 'vue-property-decorator'
    import {store} from '@/store'
    import IChat from '@/typings/IChat'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'
    import {IListResponseMeta} from '@/requester/typings'
    import AlertDialog from '@/components/Other/AlertDialog.vue'
    import {orderBy} from 'lodash'

    @Component({
        components: {
            AlertDialog
        }
    })
    export default class ChatList extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Ref('r-chat-list') chatListRef!: HTMLDivElement

        chatArray: Array<IChat> = []
        chatsLoading = false

        selectedChatToTrash: number | null = null

        get chats(): Record<string, IChat> {
            return store.getters['chat/chats']
        }

        get chatListMeta(): IListResponseMeta | null {
            return store.getters['chat/latestChatListMeta']
        }

        get currentChatId() {
            return store.getters['chat/currentChatId']
        }

        get allLoaded() {
            return this.chatListMeta && this.chatListMeta.currentPage === this.chatListMeta.pageCount
        }

        async onDelete(id: number) {
            await store.dispatch('chat/deleteChat', id)
        }

        async onScroll() {
            if (this.allLoaded) {
                return
            }

            if (this.chatListRef.scrollHeight - this.chatListRef.clientHeight - this.chatListRef.scrollTop < 300) {
                await this.loadNext()
            }
        }

        clickOnTrash(id: number, defaultClick: Function, e: MouseEvent) {
            this.selectedChatToTrash = id
            defaultClick(e)
        }

        @Watch('chats', {deep: true, immediate: true})
        updateChatArray() {
            this.chatArray = orderBy(Object.values(this.chats), (v) => v.createdAt, 'desc')
        }

        getClass(id: string) {
            return this.currentChatId  === id
                ? 'active'
                : ''
        }

        async loadNext() {
            if (this.chatsLoading) {
                return
            }

            this.chatsLoading = true
            await store.dispatch('chat/loadChats')
            this.chatsLoading = false
        }

        onClick(id: string) {
            store.commit('chat/selectChat', id)
            if (this.$route.name !== 'chat') {
                this.$router.push({name: 'chat'})
            }

            if (this.isMobile) {
                store.commit('app/setShowNavigation', false)
            }
        }

        async mounted() {
            await this.loadNext()
        }
    }
