
    import {Component, Vue} from 'vue-property-decorator'
    import HeaderOnlineSimple from '@/components/Header/HeaderOnlineSimple.vue'
    import {store} from '@/store'
    import {redirectToChatbotPage} from '@/utils'
    import { IChatbot } from '@/typings/IChatbot'
	import router from '@/router'

    @Component({
        components: {
            HeaderOnlineSimple
        }
    })
    export default class HeaderLogo extends Vue {
        get logoName() {
            return this.currentActor ? this.currentActor.act_as : 'Chat AI'
        }

        get currentChat(): IChatbot {
            return store.getters['chat/currentChat']
        }

        get currentActor() {
            if (this.$route.name !== 'chat') {
                return null
            }

            return this.currentChat?.Actor
        }

        async onChatClick() {
            if (!this.currentActor) {
				store.commit('app/setShowNavigation', false)
				await router.push({ name: 'chat' })
                return
            }

            await redirectToChatbotPage(this.currentActor)
        }
    }
