
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import {store} from '@/store'
    import lockIcon from '@/assets/icons/lock_icon.svg'
    import InlineSvgComponent from 'vue-inline-svg'
    import IAuthUser from '@/typings/IAuthUser'
    import {IActor} from '@/typings/IActor'
    import {AdaptiveMixin} from '@/mixins/AdaptiveMixin'

    @Component({
        components: {
            InlineSvgComponent,
        },
    })
    export default class CreateChatWithActorButton extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) value!: IActor

        get user(): IAuthUser {
            return store.getters['auth/me']
        }

        get disabled() {
            return false
        }

        get lockIcon() {
            return lockIcon
        }

        async createChat() {
            if (!this.user) {
                await store.dispatch('chat/delayedCreateChat', {actor: this.value})
                await this.$router.push({name: 'login'})
            }

            await store.dispatch('chat/createChat', {actor: this.value})

            await this.$router.push({name: 'chat'})
        }
    }
