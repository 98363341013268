import {Module} from 'vuex'
import Vue from 'vue'

export interface IRequestLog {
    type: 'error' | 'success'
    url: string
    useToken: boolean
    params: any
    data: any
    response: any
}

export interface IDevLogsModule {
    requestLogs: Array<string>
    clickCount: number
    devModeEnabled: boolean
}

export const dev_logs: Module<IDevLogsModule, any> = {
    namespaced: true,
    state: {
        requestLogs: [],
        clickCount: 0,
        devModeEnabled: false
    } as IDevLogsModule,
    getters: {
        clickCount: state => state.clickCount ?? 0,
        devModeEnabled: state => state.devModeEnabled ?? false,
        requestLogs: state => state.requestLogs ?? [],
    },
    mutations: {
        addLog(state, log: IRequestLog) {
            const res: Array<string> = [
                `[${new Date().toISOString()}]: `,
                `[${log.type}]: `,
                log.url,
                log.useToken ? '[with token]' : '[no token]',
                'data:  ',
                JSON.stringify(log)
            ]

            state.requestLogs.push(res.join(''))
        },

        clearClickCount(state) {
            state.clickCount = 0
        },

        increaseClickCount(state) {
            state.clickCount++

            if (state.clickCount >= 7) {
                Vue.set(state, 'devModeEnabled', true)
            }
        }
    },
}