
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class HeaderOnline extends Vue {
        get onlineClass() {
            return this.isOnline ? 'online' : 'not-online'
        }

        get isOnline() {
            return true // TODO
        }
    }
