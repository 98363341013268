import Vue from 'vue';
import Vuetify from 'vuetify'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                success: '#A0FF74',
                error: '#F74A4A',
                primary: '#FFFFFF',
            },
            dark: {
                primary: '#FFFFFF',
                success: '#A0FF74',
                error: '#F74A4A',
            }
        }
    }
});
