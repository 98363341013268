
    import {Component, Vue} from 'vue-property-decorator'
    import {store} from '@/store'
    import IAuthUser from '@/typings/IAuthUser'

    @Component
    export default class AccountFastMenu extends Vue {
        get user(): IAuthUser {
            return store.getters['auth/me']
        }

        get userName() {
            if (this.user.first_name || this.user.last_name) {
                return [
                    this.user.first_name,
                    this.user.last_name,
                ]
                    .filter(v => v)
                    .join(' ')
            }

            if (this.user.email) {
                return this.user.email
            }

            if (this.user.nickname) {
                return this.user.nickname
            }

            return `User #${this.user.id}`
        }

        toChatbots() {
            this.$router.push({name: 'chatbots'})
        }

        toAbout() {
            this.$router.push({name: 'about'})
        }

        toLogin() {
            this.$router.push({name: 'login'})
        }

        logout() {
            store.dispatch('auth/logout')
        }
    }
