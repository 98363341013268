
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {IActor} from '@/typings/IActor'
    import {redirectToChatbotPage} from '@/utils'
    import CreateChatWithActorButton from '../Actor/CreateChatWithActorButton.vue'

    @Component({
        components: {
            CreateChatWithActorButton
        }
    })
    export default class MarketplaceChartItem extends Vue {
        @Prop({required: true}) value!: IActor
        @Prop({default: true}) hasButton!: boolean

        async redirectToPage() {
            await redirectToChatbotPage(this.value)
        }
    }
