
    import {Component, Vue} from 'vue-property-decorator'
    import {store} from '@/store'

    @Component
    export default class DevViewComponent extends Vue {
        exportLogs() {
            const resultJson = {
                logs: store.getters['dev_logs/requestLogs'],
                auth: store.getters['auth/state'],
                chat: store.getters['chat/state'],
            }
            const blob = new Blob([JSON.stringify(resultJson)], { type: "text/json" });
            const link = document.createElement("a");

            link.download = 'export.json';
            link.href = window.URL.createObjectURL(blob);
            link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

            const evt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
            });

            link.dispatchEvent(evt);
            link.remove()
        }
    }
