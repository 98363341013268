import {Route} from 'vue-router'
import {store} from '@/store'
import {IRouterMeta, Permission} from '@/typings/IRouterMeta'

export async function checkPageAuth(to: Route, from: Route, next: Function) {
    const meta = to.meta as IRouterMeta

    await store.dispatch('auth/requestSelf')

    if (!meta.permissions || meta.permissions === 'no-auth') {
        next()
        return
    }

    const currentPermission: Permission = store.getters['auth/permission']

    if (meta.permissions === currentPermission) {
        next()
        return
    }

    switch (currentPermission) {
        case 'no-auth':
            next({name: 'login'})
            break
        case 'not-confirmed':
            next({name: 'confirm-account'})
            break
        case 'full-auth':
            next()
            break
    }
}