import actAsIcon from '@/assets/icons/act_as_icon.svg'
import shortcutIcon from '@/assets/icons/shortcuts_icon.svg'
import guideIcon from '@/assets/icons/guide_icon.svg'
import restorePurchaseIcon from '@/assets/icons/restore_purchase_icon.svg'

export interface IHeaderToggleButton {
    icon: string
    text: string
    path: string
}

export const Buttons: Array<IHeaderToggleButton> = [
    {
        icon: actAsIcon,
        text: 'Act as AI Assistant',
        path: 'chat',
    },
    //{
    //    icon: shortcutIcon,
    //    text: 'Manage Shortcuts',
    //    path: 'shortcut',
    //},
    //{
    //    icon: guideIcon,
    //    text: 'How-to Guide',
    //    path: 'guide',
    //},
    //{
    //    icon: restorePurchaseIcon,
    //    text: 'Account',
    //    path: 'account',
    //,
    //{
    //    icon: restorePurchaseIcon,
    //    text: 'About',
    //    path: 'landing',
    //}
]