import {BULLET} from "@/copy-util";

export type CHAT_PART_GROUP = 'number-list' | 'dot-list' | 'text-list'

export interface IMessagePartGroupRule {
    name: CHAT_PART_GROUP
    rule: (value: string) => boolean
    formatter: (value: string) => string
    universalFormatter: (index: number) => string
}

export const FORMATTER_REGEX: Record<CHAT_PART_GROUP, RegExp> = {
    'number-list': /^([0-9]+\.)/,
    'dot-list': /^(-)/,
    'text-list': /^([a-z]\.\s)/
}

export const GROUPS: Array<IMessagePartGroupRule> = [
    {
        name: 'number-list',
        rule: (v: string) => v.match(/^\s?([0-9]+)\.?\s?$/) !== null || v.match(/^\s?([0-9]+)\.\s?/) !== null,
        formatter: (v: string) => v.replace(/^([0-9]+\.)/, ''),
        universalFormatter: (i: number) => `${i}. `
    },
    {
        name: 'dot-list',
        rule: (v: string) => v.match(FORMATTER_REGEX['dot-list']) !== null,
        formatter: (v: string) => v.replace(FORMATTER_REGEX['dot-list'], ''),
        universalFormatter: () => `${BULLET} `
    },
    {
        name: 'text-list',
        rule: (v: string) => v.match(FORMATTER_REGEX['text-list']) !== null,
        formatter: (v: string) => v.replace(FORMATTER_REGEX['text-list'], ''),
        universalFormatter: (i: number) => `${String.fromCharCode(97 + i)}. `
    }
]

export interface IMessagePartGroup {
    type: CHAT_PART_GROUP | null
    texts: Array<string>
}

export function findPartGroupsByType(value: Array<Array<IMessagePartGroup>>, types: Array<CHAT_PART_GROUP | null>) {
    const result: Array<IMessagePartGroup> = []
    for (const block of value) {
        for (const blockPart of block) {
            if (types.includes(blockPart.type)) {
                result.push(blockPart)
            }
        }
    }

    return result
}

export function parseResponse(value: string) {
    const parts = splitResponseByBlocks(value)
    return parts.map(v => parseSplitBlocks(v))
}

export function splitResponseByBlocks(value: string) {
    const parts = value.split(/\n\n/)
        .filter(v => v !== '')

    return parts.map(p => p.split('\n'))
}

export function parseSplitBlocks(value: Array<string>): Array<IMessagePartGroup> {
    let lastGroup: CHAT_PART_GROUP | null = null
    let parts: Array<IMessagePartGroup> = []

    if (value.length === 1) {
        return [
            {
                type: null,
                texts: [value[0]]
            }
        ]
    }

    for (const part of value) {
        const currentGroup = getGroupByText(part)
        const currentGroupName = currentGroup?.name ?? null
        const currentGroupFormatter = currentGroup?.formatter ?? ((v) => v)

        if (currentGroupName === null || currentGroupName !== lastGroup ) {
            parts.push({
                type: currentGroupName,
                texts: []
            })
        }

        lastGroup = currentGroupName
        parts[parts.length - 1].texts.push(currentGroupFormatter(part))
    }

    return parts
}

export function getGroupByText(text: string) {
    for (const group of GROUPS) {
        if (group.rule(text)) {
            return group
        }
    }

    return null
}